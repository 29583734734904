
<template>
  <config-option apiname="moneycode" idname="moneyCodeId" labelname="หมวดหมู่ค่าใช้จ่าย"></config-option>
</template>

<script>
import ConfigOption from "./ConfigOption.vue";
export default {
  components: {
    ConfigOption
  }
};
</script>

