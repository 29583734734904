<template>
  <vx-card>
    <!-- REMOVE POP UP -->
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ลบข้อมูล"
        :active.sync="removePopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
          >ลบ {{ labelname }} {{ this.theInfo }}</vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="remove()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeRemovePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- UPDATE POP UP -->
    <template>
      <!-- UPDATE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="เปลี่ยนข้อมูล"
        :active.sync="updatePopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >{{ labelname }}</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-input class="w-full" v-model="configitem" />
          </vs-col>
        </vs-row>

        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:20px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >ชนิดรายรับ-รายจ่าย</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
            class="form-label px-2"
          >
            <v-select
              style="width: 100%;"
              v-model="moneySelected"
              :options="moneyOptions"
              :select="moneySelected"
            ></v-select>
          </vs-col>
        </vs-row>

        <vs-row style="margin-top:20px;margin-bottom:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สถานะ</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-switch style="width:50px;" color="success" v-model="theState">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="update()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeUpdatePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- CREATE POP UP -->
    <template>
      <vs-popup
        classContent="popup-example label-font"
        title="เพิ่มข้อมูล"
        :active.sync="createPopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >{{ labelname }}</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-input class="w-full" v-model="configitem" />
          </vs-col>
        </vs-row>

        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:20px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >ชนิดรายรับ-รายจ่าย</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
            class="form-label px-2"
          >
            <v-select
              style="width: 100%;"
              v-model="moneySelected"
              :options="moneyOptions"
              :select="moneySelected"
            ></v-select>
          </vs-col>
        </vs-row>

        <vs-row style="margin-top:20px;margin-bottom:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
          >สถานะ</vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-switch style="width:50px;" color="success" v-model="theState">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="create()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="createPopupActive = false"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <div class="grid-layout-container alignment-block" style="vertical-align:text-top ;width:100%;">
      <!--------------------------------------------------------------------->
      <!--  theline  -->
      <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        >
          <h3 style="margin-bottom: 20px;">{{ labelname }}</h3>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="4"
          vs-sm="4"
          vs-xs="4"
        ></vs-col>

        <!-- SAVE ITEM -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        ></vs-col>
        <!-- SAVE ITEM -->
        <!-- BACK to STOCK -->
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="2"
          vs-xs="2"
        >
          <vs-button
            vs-type="flex"
            vs-align="center"
            vs-justify="center"
            style="width:100%; padding-left:0; padding-right:0;"
            color="#7ED321"
            type="filled"
            @click="openCreatePanel()"
          >
            <span>
              <i
                style="font-size:18px; margin-right:5px;vertical-align:middle;"
                class="material-icons"
              >add_circle_outline</i>
            </span>
            <span style="vertical-align:middle;">เพิ่มรหัส</span>
          </vs-button>
        </vs-col>
        <!-- BACK to STOCK -->
      </vs-row>
      <!-- <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="form-label px-2"
        >
          <div style="width:100%; height:5px; background-color:#50E3C2;"></div>
        </vs-col>
      </vs-row>-->
      <!--------------------------------------------------------------------->
    </div>
    <!-- HEADER -->

    <vs-table pagination max-items="20" search :data="configlist">
      <template slot="thead">
        <vs-th sort-key="code">หัวข้อ</vs-th>
        <vs-th sort-key="codeType">ชนิดของหัวข้อ</vs-th>
        <vs-th sort-key="updateBy">อัพเดตล่าสุด</vs-th>
        <vs-th sort-key="updateDateTime">อัพเดตโดย</vs-th>
        <vs-th sort-key="state">สถานะ</vs-th>
        <vs-th style="text-align:end;">การจัดการ</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td style="width:25% ; padding-left:20px;">{{ tr["code"] }}</vs-td>

          <vs-td style="width:15% ">
            <span
              v-if="tr.codeType === 'รายรับ'"
              style="color:#7ED321; font-weight:bold;"
            >{{ tr.codeType }}</span>
            <span v-else style="color:#E95455; font-weight:bold;">
              {{
              tr.codeType
              }}
            </span>
          </vs-td>
          <vs-td style="width:15% ; padding-left:20px;">
            {{
            formatDate(tr.updateDateTime)
            }}
          </vs-td>
          <vs-td style="width:15% ; padding-left:20px;">
            {{
            tr.updateBy
            }}
          </vs-td>
          <vs-td style="width:15% ; padding-left:20px;">
            <vs-switch @click="changeState(tr[idname], indextr)" color="success" v-model="tr.state">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-td>
          <vs-td style="width:15% text-align:right;">
            <i
              @click="openUpdatePanel(tr[idname], indextr)"
              style="cursor:pointer; color: #5A8EE6;font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >edit</i>
            <i
              @click="openRemovePanel(tr[idname], indextr)"
              style="cursor:pointer; color:#F63B39; font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >delete_forever</i>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import shapeFormat from "../../../mixins/shapeFormat.js";
import vSelect from "vue-select";

export default {
  props: {
    // configlist: Array,
    apiname: String,
    idname: String,
    labelname: String
  },
  mixins: [shapeFormat],
  data() {
    return {
      moneyOptions: ["รายรับ", "รายจ่าย"],
      moneySelected: "รายรับ",
      theInfo: "",
      theState: true,
      theIndex: "",
      theID: "",
      configitem: "",
      dec: "",
      createPopupActive: false,
      updatePopupActive: false,
      removePopupActive: false,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      configlist: []
    };
  },
  components: {
    "v-select": vSelect
  },
  methods: {
    openCreatePanel() {
      this.createPopupActive = true;
      this.resetValue();
    },
    closeCreatePanel() {
      this.createPopupActive = false;
      this.resetValue();
    },
    create() {
      var item = {
        code: this.configitem,
        codeType: this.moneySelected,
        updateBy: "System",
        updateDateTime: new Date(),
        state: this.theState
      };
      /*****  BEGIN: AXIOS HTTP   *****/

      this.$http
        .post(
          this.$store.state.apiURL + "/api/" + this.apiname,
          item,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "บันทึกสำเร็จ",
              position: "top-center",
              text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            // console.log("200 >>> ", response.data);
            var newobj = response.data;
            item[this.idname] = newobj[this.idname];
            this.configlist.push(item);
            this.createPopupActive = false;
          } else {
            // console.log("NOT 200 >>> ");
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      /*****  END: AXIOS HTTP   *****/
    },
    resetValue() {
      this.configitem = "";
      this.moneySelected = "รายรับ";
      this.theID = "";
      this.theState = true;
    },

    openUpdatePanel(id, index) {
      this.updatePopupActive = true;
      this.theID = id;
      this.theIndex = index;
      var item = this.configlist.find(x => x[this.idname] === this.theID);

      if (item != null) {
        this.configitem = item.code;
        this.moneySelected = item.codeType;
        this.theState = item.state;
        // console.log("ITEM IS EXISTING this.thestate >>> ", this.theState);
        // if (item.state === "เปิด") {
        //   this.thestate = "เปิด";
        // } else {
        //   this.thestate = "ปิด";
        // }
      }
    },
    openRemovePanel(id, index) {
      this.removePopupActive = true;
      this.theID = id;
      this.theIndex = index;

      var item = this.configlist.find(x => x[this.idname] === this.theID);
      this.removeInfo = item.code;

      if (item != null) {
        this.theInfo = item.code;
        // console.log("ITEM IS EXISTING");
      }
    },

    closeUpdatePanel() {
      this.updatePopupActive = false;
      this.resetValue();
    },
    changeState(id, index) {
      // console.log("when change state idname >>>> ", this.idname);

      this.theID = id;
      this.theIndex = index;
      // this.configlist[this.theIndex].state = !this.configlist[this.theIndex]
      //   .state;
      this.theState = !this.configlist[this.theIndex].state;

      // console.log(
      //   "this.theID >>> ",
      //   this.theID,
      //   " >>> this.configlist[this.theIndex].state >>> ",
      //   this.configlist[this.theIndex].state
      // );

      /*****  BEGIN: AXIOS HTTP   *****/

      this.$http
        .put(
          this.$store.state.apiURL +
            "/api/" +
            this.apiname +
            "/changestate/" +
            this.theID,
          {
            state: this.theState
          },
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log("CHANGE STATUS");
          if (response.status === 200) {
            // this.$vs.notify({
            //   title: "บันทึกสำเร็จ",
            //   position: "top-center",
            //   text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
            //   iconPack: "feather",
            //   icon: "icon-alert-circle",
            //   color: "success"
            // });
          } else if (response.status === 500) {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });

      /*****  END: AXIOS HTTP   *****/
    },
    update() {
      this.configlist[this.theIndex].code = this.configitem;
      this.configlist[this.theIndex].codeType = this.moneySelected;
      // if (this.theState === false) {
      //   this.configlist[this.theIndex].state = "ปิด";
      // } else {
      //   this.configlist[this.theIndex].state = "เปิด";
      // }
      this.configlist[this.theIndex].state = this.theState;
      // console.log(
      //   "this.configlist[this.theIndex] >>> ",
      //   this.configlist[this.theIndex]
      // );

      /*****  BEGIN: AXIOS HTTP   *****/
      this.$http
        .put(
          this.$store.state.apiURL + "/api/" + this.apiname + "/" + this.theID,
          {
            code: this.configitem,
            codeType: this.moneySelected,
            state: this.configlist[this.theIndex].state
          },
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "บันทึกสำเร็จ",
              position: "top-center",
              text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            // console.log("response.data ", response.data);
            this.updatePopupActive = false;
          } else {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          // console.log("เกิดข้อผิดลาด");

          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      /*****  END: AXIOS HTTP   *****/
    },
    closeRemovePanel() {
      this.removePopupActive = false;
    },
    remove() {
      this.$http
        .delete(
          this.$store.state.apiURL + "/api/" + this.apiname + "/" + this.theID,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "ลบสำเร็จ",
              position: "top-center",
              text: "ได้ลบข้อมูลเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            // console.log("200 :: response.data  ");
            this.removePopupActive = false;
            this.configlist.splice(this.theIndex, 1);
          } else {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          // console.log("CATCH ERROR :: response.data  ");
        });
    }
  },
  created() {
    /*****  BEGIN: AXIOS HTTP   *****/
    this.$http
      .get(
        this.$store.state.apiURL + "/api/" + this.apiname,
        this.$store.state.auth.apiHeader
      )
      .then(response => {
        // console.log(response.status);
        if (response.status === 200) {
          this.configlist = response.data;
          // console.log("CREATED : response.data >>>> ", response.data);
        } else {
          this.$vs.notify({
            title: "ไม่สามารถโหลดข้อมูลได้",
            position: "top-center",
            text: "เกิดข้อผิดพลาดในการทำงาน",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      })
      .catch(error => {
        this.$vs.notify({
          title: "ไม่สามารถโหลดข้อมูลได้",
          position: "top-center",
          text: error.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
    /*****  END: AXIOS HTTP   *****/
  }
};
</script>

<style>
.vs-component .vs-switch .vs-switch-success .vs-switch-active {
  width: 50px !important;
}

.clear {
  display: none;
}
</style>
